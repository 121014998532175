import { useEffect, useState } from "react";
import { NEW_BASE_API } from "./config";
import logo from "./assets/logo.png";
import macFill from "./assets/mac-fill.png";
import windowsFill from "./assets/windows-fill.png";
import "./App.css";

function App() {
  const [newWinInfo, setNewWinInfo] = useState();
  const [newMacInfo, setNewMacInfo] = useState();

  const getInfo = (isWin) => {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open("get", NEW_BASE_API + `/latest${isWin ? "" : "-mac"}.yml`, true);
      xhr.send(null);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const text = xhr.responseText;
            const match = text.match(/url:\s*(.*)/g);
            const url = (isWin ? match?.[0] : match?.[1]).substring(4).trim();
            const version = text.match(/version:\s*(.*)/)?.[1];
            isWin && setNewWinInfo({ version, url });
            !isWin && setNewMacInfo({ version, url });
          }
        }
        resolve();
      };
      xhr.onabort = () => reject();
    });
  };

  const handleDownload = (prefixUrl, suffixUrl) => {
    if (!suffixUrl) return;
    const href = `${prefixUrl}/${suffixUrl}`;
    const el = document.createElement("a");
    el.href = href;
    const event = new MouseEvent("click");
    el.dispatchEvent(event);
  };

  useEffect(() => {
    getInfo(true, false);
    getInfo(false, false);
  }, []);
  return (
    <div className="page">
      <div className="right">
        <div className="right-content">
          <img src={logo} className="logo" alt="logo" />
          <div>
            <div className="title">
              药店健康 <span className="title-diagnosis">诊断系统</span>
            </div>
            <div className="pharmacy">Pharmacy health diagnosis system</div>
          </div>

          <div className="download">
            <div className="win-download">
              <span
                onClick={() => handleDownload(NEW_BASE_API, newWinInfo?.url)}
                className="download-text"
              >
                <img src={windowsFill} className="win-fill" alt="logo" />
                <span className="download-title">
                  Win版本下载({newWinInfo?.version})
                </span>
              </span>
            </div>

            <div className="win-download">
              <span
                onClick={() => handleDownload(NEW_BASE_API, newMacInfo?.url)}
                className="download-text"
              >
                <img src={macFill} className="mac-fill" alt="logo" />
                <span className="download-title">
                  Mac版本下载({newMacInfo?.version})
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom">
        <a
          className="archival"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          rel="noopener noreferrer"
        >
          湘ICP备19007477号-4
        </a>
        <div className="copyright">
          copyright {new Date().getFullYear()} 湖南一块医药出品
        </div>
      </div>
    </div>
  );
}

export default App;
